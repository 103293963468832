import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, useForm } from 'react-final-form';
import { InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import _ from 'lodash';
import { Button, Icon, Modal } from '@seeqdev/qomponents';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import { sqItemsApi } from '@/sdk';
import { ReportContentWorksheetAndUrl } from '@/reportEditor/components/reportContentModal/shared/ReportContentWorksheetAndUrl.molecule';
import { CancelAndSave } from '@/core/CancelAndSave.molecule';

export interface SelectWorkbenchProps extends ValidatingFormComponent<any> {
  component: 'SelectWorkbenchFormComponent';
  helpTextKey?: string;
  customErrorText?: string;
  notSelectedMessage: string;
  onChange: (value: any) => void;
  onDelete?: () => void;
  title: string;
}

export const SelectWorkbenchFormComponent: React.FunctionComponent<SelectWorkbenchProps> = (props) => {
  const {
    name,
    value,
    testId = 'selectWorkbench',
    validation,
    extendValidation,
    onChange,
    customErrorText,
    notSelectedMessage,
    required = true,
    onDelete,
    title,
  } = props;

  const { t } = useTranslation();

  const formState = useForm().getState();
  const showError =
    _.has(formState.errors, name) &&
    (_.has(formState.dirtyFields, name) || _.has(formState.dirtyFieldsSinceLastSubmit, name)) &&
    formState.hasValidationErrors;

  const [isSelectingItem, setIsSelectingItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(value);
  const [formattedItem, setFormattedItem] = useState<any>();

  useEffect(() => {
    onChange(selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    const formatValue = async () => {
      const itemOrValue = value && !value?.id ? value : selectedItem?.id;
      if (itemOrValue) {
        const result = await sqItemsApi.getItemAndAllProperties({ id: itemOrValue });
        setFormattedItem(result.data);
      }
    };
    if (value || selectedItem) {
      formatValue();
    }
  }, [value, selectedItem]);

  const defaultValidation = (value: any) => required && !value;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  const clearAndClose = () => {
    setIsSelectingItem(false);
  };

  const workbenchIcon = () => {
    return <Icon icon="fc-analysis" testId={`${testId}-icon`} type="inherit" extraClassNames="pl5 pr10" large={true} />;
  };

  const noSelectedItemResult = () => {
    return (
      <div className="sq-fairly-dark-gray text-italic" data-testid={`${testId}-notSelected`}>
        <span>{t(notSelectedMessage)}</span>
      </div>
    );
  };

  const formatItemName = (item: any) => {
    if (item?.id) {
      return item.name;
    }
    return formattedItem?.name;
  };

  const searchResult = (item: any) => (
    <div className="flexColumnContainer flexSpaceBetween flexFill ptb2">
      {(item && (
        <>
          <div className="flexColumnContainer flexCenter">{workbenchIcon()}</div>
          <div className="flexFill" data-testid={`${testId}-selected`}>
            <div className="searchResultName">
              <span className="simple-word-break">{formatItemName(item)}</span>
            </div>
          </div>
        </>
      )) ||
        noSelectedItemResult()}
    </div>
  );

  return (
    <>
      <FormFieldWrapper
        wrapperClassNames="flexFill"
        testId={testId}
        showError={showError}
        customErrorText={customErrorText}>
        <Field name={name} validate={appliedValidation}>
          {({ input, meta }) => {
            const formFieldProps = getFormFieldProps(formState, input, meta, props);
            return (
              <InputGroup>
                <div
                  className={classNames(
                    'width-maximum form-control ptb0 flexColumnContainer flexAlignCenter cursorPointer',
                    formFieldProps.extraClassNames,
                  )}
                  onClick={() => setIsSelectingItem(true)}
                  data-testid={`${testId}-value`}>
                  {searchResult(selectedItem)}
                </div>
                <InputGroup.Append>
                  {onDelete && selectedItem && (
                    <Button
                      icon="fa-trash"
                      iconStyle="theme"
                      testId={`${testId}-delete`}
                      onClick={() => {
                        onDelete();
                        setSelectedItem(undefined);
                        setFormattedItem(undefined);
                      }}
                    />
                  )}
                  <Button
                    icon="fa-plus"
                    iconStyle="theme"
                    testId={`${testId}-add`}
                    onClick={() => {
                      setIsSelectingItem(true);
                    }}
                  />
                </InputGroup.Append>
              </InputGroup>
            );
          }}
        </Field>
      </FormFieldWrapper>
      {isSelectingItem && (
        <Modal
          open={true}
          onClose={clearAndClose}
          testId="selectWorkbenchModal"
          title={t(title)}
          dialogClassName="modal-md"
          modalFooter={
            <CancelAndSave submitFn={clearAndClose} cancelFn={clearAndClose} btnDisabled={!selectedItem} values={[]} />
          }>
          <div className="report-content-modal">
            <ReportContentWorksheetAndUrl showOnlyWorkbooks={true} setSelectedItem={setSelectedItem} />
          </div>
        </Modal>
      )}
    </>
  );
};
